import {
    AUTH__USER_SAVE,
    AUTH__LOGOUT,
    AUTH__STATE_TOGGLE
} from './types'

export default {
    [AUTH__USER_SAVE](state, payload) {
        state.user = payload.user
    },
    [AUTH__LOGOUT](state) {
        state.user = {
            id: null,
            dni: null,
            email: null,
            first_name: null,
            father_last_name: null,
            mother_last_name: null,
            cpm: null,
            roles: [],
            permissions: []
        }
    },
    [AUTH__STATE_TOGGLE](state) {
        state.stateToggle = !state.stateToggle
    }
}
