import state from "./state";
import mutations from "./mutations";
import getters from "./getters";

const module = {
  state: () => state,
  mutations: mutations,
  getters: getters,
};

export default { ...module };
