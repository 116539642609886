import mutations from './mutations'
import getters from './getters'

const state = {
    data: {
        data: [],
        links: {},
        meta: {}
    },
}

export default {
    state,
    mutations,
    getters
}