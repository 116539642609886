var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('render-pagination',{attrs:{"links":_vm.links,"meta":_vm.meta},on:{"pagination-change-page":_vm.onPaginationChangePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var meta = ref.meta;
    var links = ref.links;
    var computed = ref.computed;
    var prevButtonEvents = ref.prevButtonEvents;
    var nextButtonEvents = ref.nextButtonEvents;
    var pageButtonEvents = ref.pageButtonEvents;
return _c('ul',{staticClass:"pagination pagination-sm pagination-circle mb-0 ml-sm-auto mt-1 mt-2"},[_c('li',{staticClass:"page-item",class:{ disabled: !computed.prevPageUrl }},[_c('a',_vm._g({staticClass:"page-link",attrs:{"href":"#"}},prevButtonEvents),[_c('feather',{attrs:{"type":"chevron-left"}})],1)]),_vm._l((computed.pageRange),function(page,key){return _c('li',{key:key,staticClass:"page-item",class:{
        active: page == computed.currentPage,
        disabled: page == '...',
      }},[(page == computed.currentPage)?_c('a',{staticClass:"page-link"},[_vm._v(_vm._s(page))]):_c('a',_vm._g({staticClass:"page-link",attrs:{"href":"#"}},pageButtonEvents(page)),[_vm._v(_vm._s(page))])])}),_c('li',{staticClass:"page-item",class:{ disabled: !computed.nextPageUrl }},[_c('a',_vm._g({staticClass:"page-link",attrs:{"href":"#"}},nextButtonEvents),[_c('feather',{attrs:{"type":"chevron-right"}})],1)])],2)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }