import {
    INVENTORY__INDEX,
    INVENTORY__UPDATE,
    INVENTORY__REMOVE,
    INVENTORY__CLEAR
} from './types'


export default {
    [INVENTORY__INDEX](state, payload) {
        state.data = payload
    },
    [INVENTORY__REMOVE](state, payload) {
        state.data.data.forEach((item, index) => {
            if (item.id == payload.data.id) {
                state.data.data.splice(index, 1);
                state.data.meta.total--
            }
        });
    },
    [INVENTORY__UPDATE](state, payload) {
        state.data.data.forEach((item, index) => {
            if (item.id == payload.data.id) {
                state.data.data[index] = payload.data
            }
        })
        // re-render
        let data = state.data.data
        state.data.data = []
        state.data.data = data
    },
    [INVENTORY__CLEAR](state) {
        state.data = {
            data: [],
            links: {},
            meta: {}
        }
    }
}
