<template>
  <select :name="name" :id="identifier" class="form-control">
    <option v-for="selecteditem in selecteditems" :key="selecteditem.id" :value="selecteditem.id">
      {{ selecteditem.name }}
    </option>
  </select>
</template>

<script>
  import select2 from "select2";
  import "select2/dist/js/i18n/es.js";
  export default {
    props: ["selecteditems", "identifier", "name", "url", "placeholder"],
    data() {
      return {
        select2: null,
      };
    },
    created() {},
    mounted() {
      this.select2 = $("#" + this.identifier)
        .select2({
          placeholder: this.placeholder,
          theme: "bootstrap",
          language: "es",
          minimumInputLength: 2,
          ajax: {
            url: this.url,
            data: function (params) {
              var query = {
                search: params.term,
              };
              // Query parameters will be ?search=[term]&type=public
              return query;
            },
            processResults: function (data) {
              return {
                results: data,
              };
            },
            cache: false,
          },
        })
        .on("select2:select select2:unselect", (ev) => {
          this.$emit("change", this.select2.val());
          this.$emit("select", ev["params"]["data"]);
        });
    },
    beforeDestroy() {
    this.select2.select2("destroy");
  },
  };
</script>
