import {
    WORKER__INDEX,
    WORKER__UPDATE,
    WORKER__REMOVE,
    WORKER__CLEAR
} from './types'


export default {
    [WORKER__INDEX](state, payload) {
        state.data = payload
    },
    [WORKER__REMOVE](state, payload) {
        state.data.data.forEach((access, index) => {
            if (access.id == payload.data.id) {
                state.data.data.splice(index, 1);
                state.data.meta.total--
            }
        });
    },
    [WORKER__UPDATE](state, payload) {
        state.data.data.forEach((user, index) => {
            if (user.id == payload.data.id) {
                state.data.data[index] = payload.data
            }
        })
        // re-render
        let data = state.data.data
        state.data.data = []
        state.data.data = data
    },
    [WORKER__CLEAR](state) {
        state.data = {
            data: [],
            links: {},
            meta: {}
        }
    }
}
