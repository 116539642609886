
import mutations from './mutations'
import getters from './getters'

const state = {
    user: {
        id: null,
        dni: null,
        email: null,
        first_name: null,
        father_last_name: null,
        mother_last_name: null,
        roles: [],
        permissions: []
    },
    stateToggle: false
}

export default {
    state,
    mutations,
    getters
}