require("./bootstrap");
/**
 * !IMPORT
 */
window.Vue = require('vue');
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueBus from "vue-bus";
import HttpCode from "./packages/http_code"; // Http:status code
import Snotify, { SnotifyPosition } from "vue-snotify"; // Import Snotify
import VeeValidate, { Validator } from "vee-validate";
import es from "vee-validate/dist/locale/es"; // Vee-validate: config - spanish
import VueSimpleAlert from "vue-simple-alert";
import "@fortawesome/fontawesome-free/css/all.css";
import "./sass/app.scss";
import VueFeather from "vue-feather";
import vueDirectiveTooltip from "vue-directive-tooltip";
const moment = require("moment");
require("moment/locale/es");

/**
 * VueFlatPickr
*/
import 'flatpickr/dist/flatpickr.css';

import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);

flatpickr.setDefaults({
  dateFormat: 'Y-m-d',
  altInput: true,
  altFormat: 'j M, Y'
})

import VueFlatPickr from 'vue-flatpickr-component';
Vue.use(VueFlatPickr)

/**
 * !VUE:
 * Use of extensions or components external
 */
Vue.use(VueFeather);
Vue.use(VueBus);
Vue.use(HttpCode);
Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.rightTop,
  },
});
Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(VeeValidate, {
  aria: true,
});
Validator.localize("es", es);
Vue.use(VueSimpleAlert);
Vue.use(vueDirectiveTooltip, {
  delay: 100,
  placement: "left",
  triggers: ["hover"],
  offset: 0,
});
Vue.config.productionTip = false;

/**
 * !GLOBAL COMPONENTS
 */
Vue.component(
  "page-header",
  require("./components/shared/page/Header.vue").default
);
Vue.component("select2-zh", require("./components/shared/SelectZh").default);

Vue.component("page-body", require("./components/shared/page/Body.vue").default);
Vue.component("modal", require("./components/shared/modal/Modal.vue").default);
Vue.component(
  "modal-1",
  require("./components/shared/modal/Modal1.vue").default
);

/**
 * !DIRECTIVES
 */

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");