<template>
  <div class="card card-featured card-featured-primary mb-4">
    <div class="card-header">
      <h2 class="card-title">{{ propHeader.title }}</h2>
    </div>
    <div class="card-body">
      <!-- FILTRO -->
      <slot name="content-filter"></slot>

      <!-- TABLET -->
      <div class="table-responsive">
        <table class="table table-bordered table-sm mb-0 font-inter table-zh">
          <thead class="thead-primary">
            <tr>
              <th scope="col">#</th>
              <th class="todo-mayuscula" scope="col" v-for="(col, index) in propBody.cols" :width="col.width" :key="index">
                {{ col.alias }}
              </th>
              <th scope="col" class="text-center" :width="propBody.buttons.width">ACCIÓN</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in cpd_list.data" :key="index" :id="`row_${cpd_list.meta.from + index}`">
              <td>
                {{ cpd_list.meta.from + index }}
              </td>

              <td v-for="(col, index) in propBody.cols" :key="index">
                <span v-html="mtd_getValue(row, col)"></span>
              </td>

              <td class="text-center">
                <!--Buttons-->
                <div class="justify-content-center">
                  <!--Buttons personalizados  -->
                  <div class="d-block d-md-inline" v-for="(button, i) in propBody.myButtons" :key="i">
                    <button
                      class="btn btn-xs mr-1"
                      :class="button.class == null ? 'btn-primary' : button.class"
                      v-tooltip.left="`${button.tooltip}`"
                      @click="mtd_sonWindowOpen(row, index, button)"
                      v-html="button.text"
                      v-if="mtd_showCustomButtonWithoutItems(button) && mtd_getValueButtons(button, row) && mtd_ValidateShowButton(button, row)"
                    ></button>
                    <div v-if="mtd_showCustomButtonWithItems(button)" class="dropdown">
                      <button
                        class="btn btn-outline-primary btn-sm dropdown-toggle"
                        v-html="button.text"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a
                          v-for="(item, index) in button.items"
                          :key="index"
                          class="dropdown-item"
                          @click="mtd_sonWindowOpen({ ...row, item: item.data }, index, button)"
                          >{{ item.text }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <!--End buttons personalizados-->
                  <!--Buttons default  -->
                  <template v-if="cpd_can.includes(mtd_formatPermission('edit')) && propBody.buttons.edit">
                    <button-edit @action-edit="mtd_emitActionEdit" :propRow="row" />
                  </template>
                  <template v-if="cpd_can.includes(mtd_formatPermission('destroy')) && propBody.buttons.remove">
                    <button-remove
                      :prop-alert-sms="`¿ Deseas eliminar: <b>${propModel.alias.toUpperCase()}</b> ' ${row.name ? row.name : row.identify} ' ?`"
                      :propRow="row"
                      @action-remove="mtd_emitActionRemove"
                    />
                  </template>
                  <!--End buttons default-->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--FOOTER TABLE -->
      <div class="d-flex align-items-center flex-column flex-sm-row">
        <!-- Pagination -->
        <pagination :links="cpd_list.links" :meta="cpd_list.meta" @pagination-change-page="mtd_nextPagination"></pagination>
      </div>
    </div>
    <!--Son window-->
    <div
      v-if="propBody.sonWindow"
      class="modal fade"
      id="sonWindow"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog" :class="[sonWindow.size, sonWindow.scroll ? 'modal-dialog-scrollable' : '']" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" v-html="sonWindow.title"></h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="mtd_sonWindowClose">
              <i class="far fa-times-circle"></i>
            </button>
          </div>
          <slot name="content-sonwindow"></slot>
        </div>
      </div>
    </div>
    <!--End son window-->
  </div>
</template>
<script>
  import ButtonRemove from "./buttons/Remove";
  import ButtonEdit from "./buttons/Edit";
  import Pagination from "./pagination/index";
  export default {
    components: {
      Pagination,
      ButtonEdit,
      ButtonRemove,
    },
    props: {
      propHeader: {
        required: true,
      },
      propBody: {
        required: true,
      },
      propModel: {
        required: true,
      },
    },
    data() {
      return {
        link: true,
        sonWindow: {
          title: null,
          size: null,
          scroll: false,
        },
        page: 1,
      };
    },
    computed: {
      cpd_list() {
        return this.$store.getters[this.mtd_formatGetterList()];
      },
      cpd_can() {
        return this.$store.getters["can"];
      },
    },
    methods: {
      mtd_formatGetterList: function () {
        return this.propModel.name.replace("-", "_");
      },
      mtd_getValue: function (row, col) {
        if (col.play) {
          switch (col.play.type) {
            case "link":
              if (row[col.name] != null) return `<a href='${row[col.name]}' target='_blank'>${col.play.icon}</a>`;
              else return `<i class="fas fa-times text-danger"></i>`;
              // eslint-disable-next-line no-unreachable
              break;
            case "img":
              return `<img class="${col.play.class}" src='${row[col.name]}' style="${col.play.style}" >`;
              // eslint-disable-next-line no-unreachable
              break;
            case "list":
              // eslint-disable-next-line no-case-declarations
              let html = `<ul ${col.play.class ? `class="${col.play.class}"` : ""}>`;
              row[col.name].forEach((element) => {
                html = `${html} <li ${col.play.classItem ? `class="${col.play.classItem}"` : ""}> ${element} </li>`;
              });
              return `${html}</ul>`;
              // eslint-disable-next-line no-unreachable
              break;
            default:
              return col.play[row[col.name]];
          }
        } else {
          if (row[col.name] === null || row[col.name] === "") return "-----------";
          return row[col.name];
        }
      },
      mtd_nextPagination: function (page) {
        this.page = page;
        this.$emit("action-next-pagination", this.page);
      },
      mtd_formatPermission: function (http) {
        let permission = null;
        if (this.propModel.name.substr(-1) == "s") permission = `${this.propModel.name}-${http}`;
        else if (this.propModel.name.substr(-1) == "y") {
          permission = this.propModel.name.substr(0, this.propModel.name.length - 1);
          permission = `${permission}ies-${http}`;
        } else permission = `${this.propModel.name}s-${http}`;

        return permission;
      },
      mtd_emitActionEdit: function (idOrSlug) {
        this.$emit("action-edit", idOrSlug);
      },
      mtd_emitActionRemove: function (idOrSlug) {
        this.$emit("action-remove", idOrSlug, this.page);
      },
      mtd_sonWindowClose: function () {
        // eslint-disable-next-line no-undef
        $("#sonWindow").modal("hide");
        this.$emit("action-sonwindow-close");
      },
      mtd_showCustomButtonWithoutItems: function (button) {
        if (button.items == undefined) {
          if (button.can) {
            return this.cpd_can.includes(button.can);
          } else {
            return true;
          }
        } else {
          return false;
        }
      },
      mtd_getValueButtons: function (button, row) {
        
        if (button.play) {
          let row_bd = row[button.play.name];
          switch (button.play.type) {
            case "state":
              if (button.play[row_bd]) {
                return true;
              }
              break;
            default:
              break;
          }
        } else {
          return true;
        }
      },
      mtd_ValidateShowButton: function (button, row) {
        //Validacion SHOWIF
        // eslint-disable-next-line no-prototype-builtins
        if (button.hasOwnProperty("showIf")) {
          for (const key in button.showIf) {
            const element = button.showIf[key];
            if (row[key] != element) return false;
          }
        }
        return true;
      },
      mtd_showCustomButtonWithItems: function (button) {
        if (button.items == undefined) {
          return false;
        } else {
          if (button.can) {
            return this.cpd_can.includes(button.can);
          } else {
            return true;
          }
        }
      },
      mtd_sonWindowOpen: function (row, index, button) {
        if (!button.redirect) {
          this.sonWindow.size = button.modalSize ? button.modalSize : "";
          this.sonWindow.scroll = button.scroll;
          this.sonWindow.title = button.thisTitle ? button.thisTitle : `${button.title} ${row.name}`;
        }
        this.$emit(button.component, row);
      },
    },
  };
</script>
