<template>
  <!-- tabindex="-1" -->
  <div
    class="modal fade"
    id="component-modal-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    data-backdrop="static"
    data-keyboard="false"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      :class="[propConfig.scroll ? 'modal-dialog-scrollable' : '', propConfig.size, propConfig.vertically, propConfig.position]"
    >
      <div class="modal-content" style="max-height: 100%">
        <div class="modal-header">
          <h5 class="modal-title text-primary" id="exampleModalLabel" v-html="propConfig.title"></h5>
          <button type="button" class="close" @click="mtd_close">
            <span aria-hidden="true"><i class="far fa-times-circle"></i></span>
          </button>
        </div>
        <slot name="content-modal"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["propConfig", "propModel"],
    methods: {
      mtd_close: function () {
        // eslint-disable-next-line no-undef
        $(`#${this.propConfig.id}`).modal("hide");
        this.$emit("action-close");
      },
    },
  };
</script>
