/**
 * API
 * !backend: Laravel
 */
export default {

    get(request, success, danger) {
        let config = {};
        if (request.authorization) {
            config = {
                headers: {
                    Authorization: "Bearer " + request.token,
                    "Content-Type": "application/json",
                },
            };
        } else {
            config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
        }

        axios.get(request.url, config)
            .then(
                res => {
                    success(res)
                },
                err => {
                    window.Vue.default.httpCode.accordingStatus(err.response)
                    danger(err.response)
                }
            )
            .catch((error) => {
                console.log(`Error-get en api.js ${error}`)
            });
    },


    post(request, success, danger) {
        let config = {};
        if (request.authorization) {
            config = {
                headers: {
                    Authorization: "Bearer " + request.token,
                    "Content-Type": "application/json",
                },
            };
        } else {
            config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
        }
        axios.post(request.url, request.params, config)
            .then(
                res => {
                    success(res)
                },
                err => {
                    window.Vue.default.httpCode.accordingStatus(err.response)
                    danger(err.response)
                }
            ).catch((error) => {
                console.log(`Error-post en api.js ${error}`)
            });
    },

    update(request, success, danger) {
        let config = {};
        if (request.authorization) {
            config = {
                headers: {
                    Authorization: "Bearer " + request.token,
                    "Content-Type": "application/json",
                },
            };
        } else {
            config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
        }
        axios.patch(request.url, request.params, config)
            .then(
                res => {
                    success(res)
                },
                err => {
                    window.Vue.default.httpCode.accordingStatus(err.response)
                    danger(err.response)
                }
            ).catch((error) => {
                console.log(`Error-update en api.js ${error}`)
            });
    },

    destroy(request, success, danger) {
        axios.delete(request.url, { data: request.params ? request.params : {} })
            .then(
                res => {
                    success(res)
                },
                err => {
                    window.Vue.default.httpCode.accordingStatus(err.response)
                    danger(err.response)
                }
            ).catch((error) => {
                console.log(`Error-destroy en api.js ${error}`)
            });
    },

    postWithFile(request, success, danger) {
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios.post(request.url, request.formData, config)
            .then(
                res => {
                    success(res)
                },
                err => {
                    window.Vue.default.httpCode.accordingStatus(err.response)
                    danger(err.response)
                }
            ).catch((error) => {
                console.log(`Error-post-with-file en api.js ${error}`)
            });
    },

}