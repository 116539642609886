export default {
    authentication__user(state) {
        return state.user
    },
    can(state) {
        return state.user.permissions
    },
    state_toggler(state) {
        return state.stateToggle
    },
}
