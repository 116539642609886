<template>
  <div
    class="modal fade"
    id="component-modal-1"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    data-backdrop="static"
    data-keyboard="false"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      :class="[
        propConfig.scroll ? 'modal-dialog-scrollable' : '',
        propConfig.size,
        propConfig.vertically,
      ]"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            v-html="propConfig.title"
          ></h5>
          <button type="button" class="close" @click="mtd_close">
            <span aria-hidden="true"><i class="far fa-times-circle"></i></span>
          </button>
        </div>
        <slot name="content-modal"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "c-modal-1",
  props: ["propConfig", "propModel"],
  methods: {
    mtd_close: function () {
      // eslint-disable-next-line no-undef
      $(`#${this.propConfig.id}`).modal("hide");
      this.$emit("action-close");
    },
  },
};
</script>
