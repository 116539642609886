import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "login-layout" */ "../layouts/LoginLayout.vue"
      ),
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "login" */ "../pages/Auth/Login.vue"),
      },
    ],
  },
  {
    path: "/app",
    name: "app",
    component: () =>
      import("../layouts/Auth" /* webpackChunkName: "layout.auth" */),
    meta: {},
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../pages/home" /* webpackChunkName: "home" */),
        meta: {
          restricted: true,
          permission: "home-index",
        },
      },
      {
        path: "/inventory-store",
        name: "inventory-store",
        component: () =>
          import(
            "../pages/inventory/store.vue" /* webpackChunkName: "inventory-store" */
          ),
        meta: {
          restricted: true,
          permission: "inventory-store",
        },
      },
      {
        path: "/inventory-index",
        name: "inventory-index",
        component: () =>
          import(
            "../pages/inventory/index.vue" /* webpackChunkName: "inventory-index" */
          ),
        meta: {
          restricted: true,
          permission: "inventory-index",
        },
      },
      {
        path: "/users-store",
        name: "users-store",
        component: () =>
          import(
            "../pages/users/store.vue" /* webpackChunkName: "users-store" */
          ),
        meta: {
          restricted: true,
          permission: "users-store",
        },
      },
      {
        path: "/users-index",
        name: "users-index",
        component: () =>
          import(
            "../pages/users/index.vue" /* webpackChunkName: "users-index" */
          ),
        meta: {
          restricted: true,
          permission: "users-store",
        },
      },
      {
        path: "/change-password",
        name: "change-password",
        component: () =>
          import(
            "../pages/change-password" /* webpackChunkName: "change-password" */
          ),
        meta: {
          restricted: true,
          permission: "home-index",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history", // Evita que me aparezca este numeral (#) en las rutas
  routes,
});

export default router;
