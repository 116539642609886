/**
 * !Import vue and vuex
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
/**
 * !Import modules
 */
 import global_vars from './modules/global_vars/index'
 import authentication from './modules/authentication/state'
 import worker from './modules/worker/state'
 import inventory from './modules/inventory/state'
/**
 * !Import Actions
 */
import * as actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  modules: {
    global_vars,
    authentication,
    worker,
    inventory
  },
  plugins: [
    createPersistedState({
      paths: ["authentication"]
    })
  ]
})