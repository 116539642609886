<template>
  <div class="inner-main-header">
    <h5>{{ propModel.alias }}</h5>
    <button @click="mtd_emitActionCreate" v-if="cpd_can.includes(mtd_formatPermission('store')) && propButtons.create" class="btn btn-primary btn-sm ml-2">
      <i class="ri-add-line align-middle me-2"></i> Agregar
    </button>
    <button v-if="propButtons.export" class="btn btn-primary btn-outline-primary btn-sm ml-2">
      <i class="ri-file-excel-line align-middle me-2"></i> Exportar
    </button>
    <button v-if="propButtons.import" class="btn btn-primary btn-outline-primary btn-sm ml-2">
      <i class="ri-file-upload-line align-middle me-2"></i> Importar
    </button>
    <div class="ml-auto">
      <ol class="breadcrumbs" style="margin-right: 1.5rem">
        <li>
          <a href="javascript:void(0)">
            <i :class="propModel.icon"></i>
          </a>
        </li>
        <li class="breadcrumb-item" v-for="(item, index) in propConfig.menu" :key="index">
          <a href="javascript:void(0)">{{ item }}</a>
        </li>
      </ol>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      propConfig: {
        required: true,
      },
      propModel: {
        required: true,
      },
      propButtons: {
        required: true,
      },
    },
    computed: {
      cpd_can() {
        return this.$store.getters["can"];
      },
    },
    methods: {
      mtd_emitActionCreate: function () {
        this.$emit("action-create");
      },
      mtd_formatPermission: function (http) {
        let permission = null;
        if (this.propModel.name.substr(-1) == "s") permission = `${this.propModel.name}-${http}`;
        else if (this.propModel.name.substr(-1) == "y") {
          permission = this.propModel.name.substr(0, this.propModel.name.length - 1);
          permission = `${permission}ies-${http}`;
        } else permission = `${this.propModel.name}s-${http}`;
        return permission;
      },
    },
  };
</script>
